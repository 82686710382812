import { render, staticRenderFns } from "./TransactionRecord.vue?vue&type=template&id=086cb654&scoped=true&"
import script from "./TransactionRecord.vue?vue&type=script&lang=js&"
export * from "./TransactionRecord.vue?vue&type=script&lang=js&"
import style0 from "./TransactionRecord.vue?vue&type=style&index=0&id=086cb654&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "086cb654",
  null
  
)

export default component.exports