<template>
  <div>
    <div class="swap">
      <div class="headWrap">
        <van-row type="flex" justify="space-between">
          <van-col class="title">{{ $t('lang.swap1') }}</van-col>
          <van-col>
            <img src="@/assets/img/set.png" @click="handleSettingShow" alt="" class="iconSet" />
            <img src="@/assets/img/record.png" @click="handleRecordShow" alt="" class="iconRecord" />
          </van-col>
        </van-row>
        <!-- <div class="text">{{ $t('lang.swap2') }}</div> -->
      </div>

      <div class="container">
        <van-row type="flex" justify="space-between">
          <van-col class="title xhlbox">
            <div>
              {{ $t('lang.swap3') }}<span v-if="inputSource == 'to' && fromInput != ''">({{ $t('lang.swap50') }})</span>
            </div>
            <img class="xhlimg" v-if="fromCur.symbol != 'BNB'" @click="addToken(fromCur)" src="../../assets/img/xhl.png" alt="" />
          </van-col>

          <div class="fbnum" v-if="isFocused">
            <div class="maxWord" @click="handleToMax2">25%</div>
            <div class="maxWord" @click="handleToMax5">50%</div>
            <div class="maxWord" @click="handleToMax7">75%</div>
            <div class="maxWord" @click="handleToMax">MAX</div>
          </div>

          <van-col v-else @click="handleToMax" class="yebox"><img src="../../assets/img/ye.png" alt="" />{{ significantDigits(fromCur.balance) }}</van-col>
        </van-row>
        <div class="box">
          <van-row type="flex" justify="space-between" class="btRow">
            <van-col>
              <div v-if="fromCur.name" class="selectedCurrency">
                <div class="tokenDetail" @click="handleGetCurrency('from')">
                  <van-image round class="tokenImg" height="32px" :src="fromCur.image" />
                  <div class="tokenSymbol">{{ fromCur.symbol }}</div>
                  <van-image height="20px" :src="require('@/assets/img/bottom.png')" />
                </div>
              </div>
              <div v-else class="tokenSymbol">{{ $t('lang.swap5') }}</div>
            </van-col>
            <van-col :span="12" class="title">
              <van-field @focus="handleFocus" @blur="handleBlur" type="number" class="inputsty" v-model="fromInput" @input="hanldeInputFrom" placeholder="0.0" />
              <div v-if="fromInput" class="value-display">≈ {{ fromValueUsd }} USD</div>
            </van-col>
          </van-row>
        </div>
        <div class="iconDown">
          <div class="linebox"></div>

          <img src="@/assets/img/opposite.png" @click="handleTurnAround" alt="" class="iconDown" />
        </div>
        <van-row type="flex" justify="space-between">
          <van-col class="title xhlbox">
            <div>
              {{ $t('lang.swap32') }} <span v-if="inputSource == 'from' && toInput != ''">({{ $t('lang.swap50') }})</span>
            </div>
            <img class="xhlimg" v-if="toCur.symbol != 'BNB'" @click="addToken(toCur)" src="../../assets/img/xhl.png" alt="" />
          </van-col>
          <div class="fbnum" v-if="isFocusedto">
            <div class="maxWord" @click="handleToMax2to">25%</div>
            <div class="maxWord" @click="handleToMax5to">50%</div>
            <div class="maxWord" @click="handleToMax7to">75%</div>
            <div class="maxWord" @click="handleToMaxto">MAX</div>
          </div>
          <van-col class="yebox" v-else @click="handleToMaxto">
            <img src="../../assets/img/ye.png" alt="" />
            {{ significantDigits(toCur.balance) }}</van-col
          >
        </van-row>
        <div class="box">
          <van-row type="flex" justify="space-between" class="btRow">
            <van-col @click="handleGetCurrency('to')">
              <div v-if="toCur.name" class="selectedCurrency">
                <div class="tokenDetail">
                  <van-image round class="tokenImg" height="32px" :src="toCur.image" />
                  <div class="tokenSymbol">{{ toCur.symbol }}</div>
                  <van-image height="20px" :src="require('@/assets/img/bottom.png')" />
                </div>
              </div>
              <div v-else class="tokenSymbol">{{ $t('lang.swap5') }}</div>
            </van-col>
            <van-col :span="12" class="title"
              ><van-field @focus="handleFocusto" @blur="handleBlurto" class="inputsty" type="number" v-model="toInput" @input="hanldeInputTo" placeholder="0.0" />
              <div v-if="toInput" class="value-display">≈ {{ toValueUsd }} USD</div>
            </van-col>
          </van-row>
        </div>

        <!-- 选择币弹框 -->
        <van-popup v-if="tokenListShow" v-model="tokenListShow" round>
          <currency-list @listenClose="handleTokenListClose" @listenSelecteCurrency="handlerSelecteCurrency" />
        </van-popup>
        <van-popup v-model="settingShow" round>
          <SettingsModal @listenClose="handleSettingClose" />
        </van-popup>
        <!-- <van-popup v-model="swapDetailShow" :round="true" @closed="handleSwapDetailClosed">
          <swap-detail @listenClose="handleSwapDetailClose" @listenConfirm="handleConfirm" :fromCur="fromCur" :toCur="toCur" :swapDetail="swapDetail" />
        </van-popup> -->
        <van-popup v-model="pendingDetailShow" round @closed="handlePendingSwapClosed">
          <pending-swap @listenClose="handlePendingSwapClose" :pendingDetail="pendingDetail" :fromCur="fromCur" :toCur="toCur" />
        </van-popup>
        <van-popup v-model="transactionRecordShow" round>
          <transaction-record @listenClose="handleTransactionRecordClose" />
        </van-popup>
      </div>
    </div>
    <div class="message swap">
      <van-button type="primary" class="swapBtn" v-if="!account" @click="handleLogin">{{ $t('lang.swap9') }}</van-button>
      <van-button type="default" class="swapBtn" v-else-if="insufficientLiquidityShow" disabled>{{ $t('lang.swap12') }}</van-button>
      <van-button type="default" class="swapBtn" v-else-if="getNoInputBtnShow" disabled>{{ $t('lang.swap10') }}</van-button>
      <van-button type="default" class="swapBtn" v-else-if="getNoBalanceBtnShow" disabled>{{ $t('lang.swap11') }}</van-button>
      <van-button type="default" class="swapBtn" v-else-if="getNoSelectBtnShow" disabled>{{ $t('lang.swap13') }}</van-button>
      <van-button type="primary" class="swapBtn" :loading="approveLoading" loading-text="Approving..." v-else-if="allowanceToRouter" @click="handleApprove">{{ $t('lang.swap14') }}</van-button>
      <van-button type="primary" class="swapBtn" v-else-if="getWrap" @click="handleDeposit">{{ $t('lang.swap15') }}</van-button>
      <van-button type="primary" class="swapBtn" v-else-if="getWithdraw" @click="handleWithdraw">{{ $t('lang.swap16') }}</van-button>
      <van-button type="primary" class="swapBtn" v-else @click="handleSwap">{{ $t('lang.swap17') }}</van-button>

      <div v-if="priceShow">
        <van-row type="flex" justify="space-between" class="price" @click="changePriceDirection">
          <!-- <van-col class="text">{{ $t('lang.swap7') }}</van-col> -->
          <van-col v-if="priceDirection"
            ><van-image class="opppsite" :src="require('@/assets/img/load.png')" /> 1{{ toCur.symbol }}<van-image class="changimg" :src="require('@/assets/img/chang.png')" /> {{ price }}
            {{ fromCur.symbol }}
          </van-col>
          <van-col v-else>
            <van-image class="opppsite" :src="require('@/assets/img/load.png')" /> 1 {{ fromCur.symbol }}<van-image class="changimg" :src="require('@/assets/img/chang.png')" /> {{ price }}
            {{ toCur.symbol }}
          </van-col>
          <div class="Feebox">
            Fee {{ swapDetail.fee }} {{ fromCur.symbol }} <img v-if="showdetails" @click="showdetails = !showdetails" :src="require('@/assets/img/top.png')" alt="" />
            <img v-else @click="showdetails = !showdetails" :src="require('@/assets/img/bottom.png')" alt="" />
          </div>
        </van-row>
        <div v-if="showdetails">
          <van-row type="flex" justify="space-between" class="slipWrap">
            <van-col class="text">{{ $t('lang.swap8') }}</van-col>
            <van-col>{{ slippage }}%</van-col>
          </van-row>
          <van-row type="flex" justify="space-between" class="slipWrap">
            <van-col class="text">{{ $t('lang.swap30') }}</van-col>
            <div>{{ swapDetail.inputOutMin }} {{ toCur.symbol }}</div>
          </van-row>
          <van-row type="flex" justify="space-between" class="slipWrap">
            <van-col class="text">{{ $t('lang.swap55') }}</van-col>
            <div>{{ swapDetail.fee }} {{ fromCur.symbol }}</div>
          </van-row>
          <van-row type="flex" justify="space-between" class="slipWrap">
            <van-col class="text">{{ $t('lang.swap56') }}</van-col>
            <div class="pathroutersty">
              <div class="" v-for="(item, i) in pathrouter" :key="i">{{ item }}</div>
            </div>
          </van-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrencyList from '@/components/CurrencyList.vue';
// import SwapDetail from '@/views/swap/SwapDetail.vue';
import PendingSwap from '@/views/swap/PendingSwap.vue';
import TransactionRecord from '@/views/swap/TransactionRecord.vue';

import { mapState } from 'vuex';
import { formatAmount, parseAmount, accMul, accDiv, toFixed, accAdd, accSub, accGt, significantDigits, gasProcessing } from '@/utils/format.js';
import { isBnb } from '@/utils/verify.js';
import { getJackRouterAddress } from '@/utils/addressHelp';
import { MaxUint256 } from '@ethersproject/constants';
import { getErc20Contract, getJackRouterContract, getJackPairContract, getJackFactoryContract } from '@/utils/contractHelp';
import SettingsModal from '@/components/SettingsModal.vue';
import { simpleRpcProvider } from '@/utils/provider';
import { useJackRouterContract, useErc20Contract, useWethContract } from '@/utils/useContract';
import { getAllPair } from '@/utils/pairHelp';
import web3 from 'web3';
import DEFAULT_TOKEN_LIST from '@/config/constants/tokenList';
import { BNB } from '@/config/constants/bnb';
import { BigNumber } from 'bignumber.js';
export default {
  name: 'Home',
  data() {
    return {
      showdetails: false,
      isFocused: false,
      isFocusedto: false,

      storeCurList: [], //自定义代币
      currencyLists: [],
      cachedCoins: null,
      fromValueUsd: 0, // 总价值（从，美元计）
      toValueUsd: 0, // 总价值（到，美元计）
      lastFetchTime: 0,
      childArray: [],
      fromBalance: '',
      tokenListShow: false,
      settingShow: false,
      isShow: true,
      approveLoading: false, //授权loading
      pendingDetailShow: false,
      transactionRecordShow: false, //交易记录
      pendingDetail: { status: 0, hash: '', fromInput: '', toInput: '', errormess: '' },
      allpaths: [], //所有的多路径
      currentPaths: [], //当前所有多路径
      pathIndex: -1, //当前选择的多路径
      insufficientLiquidityShow: false, //当固定输出时，大于池子深度
      fromInput: '', //from输入框
      fromCur: {
        name: 'BNB',
        symbol: 'BNB',
        address: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
        chainId: 56,
        decimals: 18,
        balance: '',
        image: 'https://raw.githubusercontent.com/Sexy-J/JieSwap/main/src/assets/img/bnb.png',
      },
      toInput: '',
      toCur: {
        balance: '',
        name: '',
        symbol: '',
        address: '',
        chainId: '',
        decimals: '',
        image: '',
      },
      tokenSource: 'from',
      priceDirection: true,
      price: '',
      priceShow: false,
      inputSource: '',
      allowanceToRouter: false, //显示是否需要授权
      swapDetailShow: false,
      swapDetail: { fromInput: '', toInput: '', amountIn: 0, amountInMax: 0, amountOut: 0, amountOutMin: 0, path: [], to: '', deadline: 0, inputSource: 'from', fee: 0 },
      pathrouter: [],
      debounceTimer: null, // 防抖定时器
      debounceTimerto: null, // 防抖定时器
    };
  },
  mounted() {
    // this.getcurrencyLists();
    if (sessionStorage.getItem('fromCurcurrency')) {
      let data = sessionStorage.getItem('fromCurcurrency');
      this.fromCur = JSON.parse(data);
    }
    if (sessionStorage.getItem('toCurcurrency')) {
      let data = sessionStorage.getItem('toCurcurrency');
      this.toCur = JSON.parse(data);
    }
    this.handleGetAllPair();
    this.handleApproveBtnShow();
    this.handleCurBalance();
  },
  beforeDestroy() {
    window.clearInterval(this.timer);
    window.clearInterval(this.timerBalance);
  },
  async created() {
    this.initialization();
  },
  watch: {
    account() {
      this.initialization();
    },
    multipath() {
      this.getAmountsIn();
      this.getAmountsOut();
    },
    slippage() {
      this.getswapDetail();
    },
  },
  components: {
    CurrencyList,
    SettingsModal,
    // SwapDetail,
    PendingSwap,
    TransactionRecord,
  },
  computed: {
    ...mapState(['account', 'provider', 'gasPrice', 'slippage', 'deadline', 'multipath']),

    // 是否需要显示余额不足提示
    getNoBalanceBtnShow() {
      if (accGt(this.fromInput, this.fromCur.balance)) {
        return true;
      }
      return false;
    },
    //是否需要显示inputBtn按钮
    getNoInputBtnShow() {
      if ((parseFloat(this.fromInput) === 0 || this.fromInput === '') && this.fromCur.address !== '') {
        return true;
      } else if ((parseFloat(this.toInput) === 0 || this.toInput === '') && this.toCur.address !== '') {
        return true;
      }
      return false;
    },
    // 是否需要显示选择币种
    getNoSelectBtnShow() {
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return true;
      }
      return false;
    },
    // 是否BNB - WBNB
    getWrap() {
      if (isBnb(this.fromCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
    // 是否WBNB - BNB
    getWithdraw() {
      if (isBnb(this.toCur) && this.toCur.address == this.fromCur.address) {
        return true;
      }
      return false;
    },
  },
  methods: {
    async addToken(item) {
      const tokenOptions = {
        type: 'ERC20', // 代币类型
        options: item,
      };
      try {
        // 调用 MetaMask 的 `wallet_watchAsset` 方法
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: tokenOptions,
        });
      } catch (error) {
        console.error('添加代币失败：', error);
      }
    },

    handleFocus() {
      this.isFocused = true; // 光标进入输入框
    },
    handleBlur() {
      setTimeout(() => {
        this.isFocused = false;
      }, 1000); // 延迟
    },
    handleFocusto() {
      this.isFocusedto = true; // 光标进入输入框
    },
    handleBlurto() {
      setTimeout(() => {
        this.isFocusedto = false;
      }, 1000); // 延迟
    },
    // async getcurrencyLists() {
    //   try {
    //     this.storeCurList = localStorage.getItem('curList') ? JSON.parse(localStorage.getItem('curList')) : [];
    //   } catch (e) {
    //     this.storeCurList = [];
    //   }
    //   this.currencyLists = [BNB, ...DEFAULT_TOKEN_LIST.tokens, ...this.storeCurList];
    //   console.log(this.currencyLists);
    // },
    async updateTokenPriceAndValue() {
      try {
        this.fromValueUsd = await this.calculateUsdAmount(this.fromCur.symbol, this.fromInput);
        this.toValueUsd = await this.calculateUsdAmount(this.toCur.symbol, this.toInput);
      } catch (error) {
        console.error('Error updating token price and value:', error);
      }
    },
    // 计算代币等值 USD
    async calculateUsdAmount(symbol, amount) {
      const coinsList = await this.getCachedCoinList();
      const price = await this.fetchTokenPrice(symbol, coinsList);
      const usdAmount = (price * amount).toFixed(2);
      // console.log(`${amount} ${symbol} = ${usdAmount} USD`);
      return usdAmount;
    },
    // 从 CoinGecko API 获取指定代币的价格
    // async fetchTokenPrice(symbol) {
    //   const tokenId = await this.fetchTokenId(symbol);
    //   console.log(tokenId);
    //   if (!tokenId) {
    //     console.error('Token ID not found for address:', symbol);
    //     return 0;
    //   }

    //   try {
    //     const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${tokenId}&vs_currencies=usd`);
    //     console.log(response);

    //     if (!response.ok) throw new Error('Failed to fetch token price');

    //     const data = await response.json();
    //     // console.log(data);
    //     return data[tokenId]?.usd || 0; // Return price or fallback to 0
    //   } catch (error) {
    //     console.error('Error fetching price:', error);
    //     return 0; // Return fallback price
    //   }
    // },
    async fetchTokenId(symbol) {
      try {
        // 如果没有缓存，获取代币列表
        if (!this.cachedCoins) {
          const response = await fetch('https://api.coingecko.com/api/v3/coins/list');
          this.cachedCoins = await response.json();
          localStorage.setItem('cachedCoins', JSON.stringify(this.cachedCoins));
        } else {
          const cachedCoins = localStorage.getItem('cachedCoins');
          this.cachedCoins = JSON.parse(cachedCoins);
        }

        // 匹配地址
        const token = this.cachedCoins.find(coin => coin.symbol?.toLowerCase() == symbol.toLowerCase());

        return token?.id || null; // 如果未找到匹配的代币，返回 null
      } catch (error) {
        console.error('Error fetching token ID:', error);
        return null;
      }
    },
    async getAllSupportedCoins() {
      try {
        const response = await fetch('https://api.coingecko.com/api/v3/coins/list');
        if (!response.ok) {
          throw new Error(`Failed to fetch coins list: ${response.status}`);
        }

        const data = await response.json();
        return data; // 返回数组
      } catch (error) {
        console.error('Error fetching coin list:', error);
        return []; // 返回空数组
      }
    },
    async getCachedCoinList() {
      const cacheKey = 'coinListCache';
      const cacheExpiry = 24 * 60 * 60 * 1000; // 缓存 24 小时

      try {
        // 检查缓存是否存在且未过期
        const cached = localStorage.getItem(cacheKey);
        const cachedTime = localStorage.getItem(`${cacheKey}_time`);
        // console.log('Cached coin list:', cached); // 打印缓存内容
        // console.log('Cached time:', cachedTime); // 打印缓存时间
        if (cached && cachedTime && Date.now() - cachedTime < cacheExpiry) {
          const cachedCoins = JSON.parse(cached);
          // console.log('Using cached coin list:', cachedCoins);
          if (Array.isArray(cachedCoins)) {
            return cachedCoins; // 缓存的数据是有效数组
          }
        }
        // console.log(this.getAllSupportedCoins());
        // 如果缓存不存在或无效，重新获取数据
        const coins = await this.getAllSupportedCoins();
        // console.log(coins);
        if (Array.isArray(coins)) {
          localStorage.setItem(cacheKey, JSON.stringify(coins));
          localStorage.setItem(`${cacheKey}_time`, Date.now());
          return coins;
        } else {
          throw new Error('Fetched coin list is not an array');
        }
      } catch (error) {
        console.error('Error in getCachedCoinList:', error);
        return []; // 返回空数组作为默认值
      }
    },
    // async getAllPrices(tokenIds) {
    //   try {
    //     const ids = tokenIds.join(','); // 将数组转为逗号分隔字符串
    //     const url = `https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`;
    //     const response = await fetch(url);
    //     const data = await response.json();
    //     console.log('Prices:', data);
    //     return data; // 返回所有代币的价格
    //   } catch (error) {
    //     console.error('Error fetching prices:', error);
    //     return {};
    //   }
    // },
    // async getAllPricesInBatches(tokenIds, batchSize = 100) {
    //   const prices = {};
    //   for (let i = 0; i < tokenIds.length; i += batchSize) {
    //     const batch = tokenIds.slice(i, i + batchSize);
    //     const batchPrices = await this.getAllPrices(batch);
    //     Object.assign(prices, batchPrices); // 合并批量结果
    //   }
    //   console.log('All Prices:', prices);
    //   return prices;
    // },
    // async fetchAllTokensAndPrices() {
    //   try {
    //     // 获取代币列表（带缓存）
    //     const coins = await this.getCachedCoinList();

    //     // 提取代币的 `id`
    //     const tokenIds = coins.map(coin => coin.id);

    //     // 分批查询所有代币价格
    //     // const prices = await this.getAllPricesInBatches(tokenIds);
    //     // console.log('Final Prices:', prices);

    //     // return prices; // 返回所有代币的价格
    //   } catch (error) {
    //     console.error('Error fetching tokens and prices:', error);
    //   }
    // },
    // 根据 symbol 获取价格
    async fetchTokenPrice(symbol, coinsList) {
      const coin = coinsList.find(item => item.symbol.toLowerCase() === symbol.toLowerCase());
      if (!coin) {
        console.error(`在币种列表中未找到符号 "${symbol}"`);
        return 0;
      }
      const url = `https://api.coingecko.com/api/v3/simple/price?ids=${coin.id}&vs_currencies=usd`;

      // 重试逻辑，处理 429 错误
      const maxRetries = 5; // 最大重试次数
      let attempts = 0; // 当前尝试次数
      let delay = 1000; // 初始延迟 1 秒

      while (attempts < maxRetries) {
        try {
          const response = await fetch(url);

          // 检查响应是否成功
          if (response.ok) {
            const data = await response.json();
            return data[coin.id]?.usd || 0;
          }

          // 如果收到 429 错误（请求过多）
          if (response.status === 429) {
            console.warn(`请求过多，${delay / 1000}秒后重试...`);
            await new Promise(resolve => setTimeout(resolve, delay)); // 延迟后重试
            delay *= 2; // 指数退避策略
            attempts++;
          } else {
            // 如果是其他错误，抛出异常
            throw new Error(`获取代币价格失败: ${response.statusText}`);
          }
        } catch (error) {
          console.error('获取代币价格时出错:', error);
          return 0;
        }
      }

      // 达到最大重试次数后仍然失败，返回 0
      console.error('达到最大重试次数，无法获取代币价格。');
      return 0;
    },

    handleLogin() {
      this.$store.dispatch('setWallet');
    },
    async handleDeposit() {
      const wethContract = useWethContract();
      const payableAmount = parseAmount(this.fromInput);
      const gas = await wethContract.methods.deposit().estimateGas({ from: this.account, value: payableAmount });
      wethContract.methods
        .deposit()
        .send({ from: this.account, value: payableAmount, gas: gasProcessing(gas) * 100, gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap15')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {})
        .on('error', function (error, receipt) {
          throw error;
        });
    },
    async handleWithdraw() {
      const wethContract = useWethContract();
      const wad = parseAmount(this.fromInput);
      const gas = await wethContract.methods.withdraw(wad).estimateGas({ from: this.account });
      wethContract.methods
        .withdraw(wad)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap16')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {})
        .on('error', function (error, receipt) {
          throw error;
        });
    },
    handleSwapDetailClose() {
      this.swapDetailShow = false;
    },
    handleSwapDetailClosed() {
      this.swapDetail = { fromInput: '', toInput: '', amountIn: 0, amountInMax: 0, amountOut: 0, amountOutMin: 0, path: [], to: '', deadline: 0, inputSource: 'from', fee: 0 };
    },
    handlePendingSwapClose() {
      this.pendingDetailShow = false;
    },
    handleTransactionRecordClose() {
      this.transactionRecordShow = false;
    },
    handlePendingSwapClosed() {
      this.pendingDetail = { status: 0, hash: '', fromInput: '', toInput: '', errormess: '' };
    },
    handleTokenListClose() {
      this.tokenListShow = false;
      this.handleTokenListClose();
    },
    handleSettingClose() {
      this.settingShow = false;
    },

    // 获取两边币种余额
    async handleCurBalance() {
      if (this.account) {
        if (isBnb(this.fromCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.fromCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.fromCur) && this.fromCur.address != '') {
          const erc20Contract = getErc20Contract(this.fromCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.fromCur.balance = formatAmount(balance, this.fromCur.decimals);
        }

        if (isBnb(this.toCur)) {
          const balance = await simpleRpcProvider.eth.getBalance(this.account);
          this.toCur.balance = formatAmount(balance);
        }

        if (!isBnb(this.toCur) && this.toCur.address != '') {
          const erc20Contract = getErc20Contract(this.toCur.address);
          const balance = await erc20Contract.methods.balanceOf(this.account).call();
          this.toCur.balance = formatAmount(balance, this.toCur.decimals);
        }
      }
    },
    // 闪兑
    handleSwap() {
      this.getswapDetail();
      // this.swapDetailShow = true;
      this.handleConfirm();
    },
    getswapDetail() {
      const slippage = accDiv(this.slippage, 100);
      this.swapDetail.inputInMax = significantDigits(accAdd(this.fromInput, accMul(this.fromInput, slippage)));
      this.swapDetail.inputOutMin = significantDigits(accSub(this.toInput, accMul(this.toInput, slippage)));
      this.swapDetail.inputSource = this.inputSource;
      this.swapDetail.price = this.price;
      this.swapDetail.fromInput = this.fromInput;
      this.swapDetail.toInput = this.toInput;
      this.swapDetail.fee = accMul(this.swapDetail.inputInMax, 0.003);
    },
    handleConfirm() {
      this.pendingDetail = { status: 0, hash: '', fromInput: '', toInput: '', errormess: '' };

      const slippage = accDiv(this.slippage, 100);
      const amountIn = parseAmount(this.fromInput, this.fromCur.decimals);
      const amountInMax = toFixed(accAdd(amountIn, accMul(amountIn, slippage)), 0);
      const amountOut = parseAmount(this.toInput, this.toCur.decimals);
      // console.log(amountOut);
      const amountOutMin = toFixed(accSub(amountOut, accMul(amountOut, slippage)), 0);

      let path = [];

      const currentPath = this.currentPaths[this.pathIndex];
      if (currentPath.length == 1) {
        path = [this.fromCur.address, this.toCur.address];
      }

      if (currentPath.length == 2) {
        path = [this.fromCur.address, currentPath[0].toCur.address, currentPath[1].toCur.address];
      }

      const to = this.account;
      const deadline = accAdd(accDiv(Date.parse(new Date()), 1000), accMul(this.deadline, 60));
      this.pendingDetail.fromInput = this.fromInput;
      this.pendingDetail.toInput = this.toInput;
      if (isBnb(this.fromCur) && this.inputSource == 'from') {
        this.swapExactETHForTokens(amountIn, amountOutMin, path, to, deadline);
        return;
      }

      if (isBnb(this.fromCur) && this.inputSource == 'to') {
        this.swapETHForExactTokens(amountOut, amountInMax, path, to, deadline);
        return;
      }

      if (isBnb(this.toCur) && this.inputSource == 'from') {
        this.swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline);
        return;
      }

      if (isBnb(this.toCur) && this.inputSource == 'to') {
        this.swapTokensForExactETH(amountOut, amountInMax, path, to, deadline);
        return;
      }
      if (this.inputSource == 'from') {
        this.swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline);
        return;
      }

      if (this.inputSource == 'to') {
        this.swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline);
        return;
      }
    },
    async swapExactETHForTokens(amountIn, amountOutMin, path, to, deadline) {
      this.pendingDetailShow = true;
      // console.log(amountIn, amountOutMin, path, to, deadline);
      const jackRouterContract = useJackRouterContract();
      // const gas = await jackRouterContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, to, deadline).estimateGas({ from: this.account, value: amountIn });
      let gas;
      try {
        // 尝试估算 gas
        gas = await jackRouterContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, to, deadline).estimateGas({ from: this.account, value: amountIn });
      } catch (error) {
        const jsonString = error.message.replace('Internal JSON-RPC error.\n', '');
        let errorDetails = JSON.parse(jsonString);
        this.pendingDetail.status = 2;
        this.pendingDetail.errormess = errorDetails.message;
        return; // 直接返回，停止后续操作
      }

      // this.swapDetailShow = false;

      jackRouterContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(amountOutMin, path, to, deadline)
        .send({ from: this.account, value: amountIn, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          console.log(error.message);
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },
    async swapETHForExactTokens(amountOut, amountInMax, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(amountOut, path, to, deadline)
        .estimateGas({ from: this.account, value: amountInMax })
        .catch(async e => {
          const gas = await jackRouterContract.methods.swapExactETHForTokensSupportingFeeOnTransferTokens(amountOut, path, to, deadline).estimateGas({ from: this.account, value: amountInMax });
          // this.swapDetailShow = false;
          this.pendingDetailShow = true;
          jackRouterContract.methods
            .swapETHForExactTokens(amountOut, path, to, deadline)
            .send({ from: this.account, value: amountInMax, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
            .on('transactionHash', hash => {
              const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              this.pendingDetail.errormess = error.message;
              throw error;
            });
        });
      this.swapDetailShow = false;
      this.pendingDetailShow = true;
      jackRouterContract.methods
        .swapExactETHForTokensSupportingFeeOnTransferTokens(amountOut, path, to, deadline)
        .send({ from: this.account, value: amountInMax, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },
    async swapExactTokensForETH(amountIn, amountOutMin, path, to, deadline) {
      //  this.swapDetailShow = false;
      this.pendingDetailShow = true;
      const jackRouterContract = useJackRouterContract();
      // const gas = await jackRouterContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline).estimateGas({ from: this.account });
      let gas;
      try {
        gas = await jackRouterContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline).estimateGas({ from: this.account });
      } catch (error) {
        const jsonString = error.message.replace('Internal JSON-RPC error.\n', '');
        let errorDetails = JSON.parse(jsonString);
        this.pendingDetail.status = 2;
        this.pendingDetail.errormess = errorDetails.message;
        return; // 直接返回，停止后续操作
      }
      jackRouterContract.methods
        .swapExactTokensForETHSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },
    async swapTokensForExactETH(amountOut, amountInMax, path, to, deadline) {
      // this.swapDetailShow = false;
      this.pendingDetailShow = true;
      const jackRouterContract = useJackRouterContract();
      let gas;
      // const gas = await jackRouterContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(amountInMax, amountOut, path, to, deadline).estimateGas({ from: this.account });
      // const gas = await jackRouterContract.methods.swapTokensForExactETH(amountOut, amountInMax, path, to, deadline).estimateGas({ from: this.account });
      try {
        gas = await jackRouterContract.methods.swapExactTokensForETHSupportingFeeOnTransferTokens(amountInMax, amountOut, path, to, deadline).estimateGas({ from: this.account });
      } catch (error) {
        const jsonString = error.message.replace('Internal JSON-RPC error.\n', '');
        let errorDetails = JSON.parse(jsonString);
        this.pendingDetail.status = 2;
        this.pendingDetail.errormess = errorDetails.message;
        return; // 直接返回，停止后续操作
      }
      jackRouterContract.methods
        .swapTokensForExactETH(amountOut, amountInMax, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },
    async swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline) {
      const jackRouterContract = useJackRouterContract();
      const gas = await jackRouterContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
        .estimateGas({ from: this.account })
        .catch(async e => {
          const gas = await jackRouterContract.methods.swapExactTokensForTokens(amountIn, amountOutMin, path, to, deadline).estimateGas({ from: this.account });
          this.swapDetailShow = false;
          this.pendingDetailShow = true;
          jackRouterContract.methods
            .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
            .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
            .on('transactionHash', hash => {
              const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
              const title = this.$t('lang.swap18');
              const transactionsDetail = {
                hash,
                title,
                message,
                addedTime: new Date().getTime(),
                from: this.account,
              };
              this.$store.commit('SETTRANSACTIONS', transactionsDetail);
              this.pendingDetail.status = 1;
              this.pendingDetail.hash = hash;
            })
            .on('error', (error, receipt) => {
              this.pendingDetail.status = 2;
              this.pendingDetail.errormess = error.message;
              throw error;
            });
        });
      this.swapDetailShow = false;
      this.pendingDetailShow = true;
      jackRouterContract.methods
        .swapExactTokensForTokensSupportingFeeOnTransferTokens(amountIn, amountOutMin, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', (error, receipt) => {
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },
    async swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline) {
      // this.swapDetailShow = false;
      this.pendingDetailShow = true;
      const jackRouterContract = useJackRouterContract();
      // const gas = await jackRouterContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountInMax, amountOut, path, to, deadline).estimateGas({ from: this.account });
      // const gas = await jackRouterContract.methods.swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline).estimateGas({ from: this.account });
      let gas;
      try {
        gas = await jackRouterContract.methods.swapExactTokensForTokensSupportingFeeOnTransferTokens(amountInMax, amountOut, path, to, deadline).estimateGas({ from: this.account });
      } catch (error) {
        const jsonString = error.message.replace('Internal JSON-RPC error.\n', '');
        let errorDetails = JSON.parse(jsonString);
        this.pendingDetail.status = 2;
        this.pendingDetail.errormess = errorDetails.message;
        return; // 直接返回，停止后续操作
      }
      jackRouterContract.methods
        .swapTokensForExactTokens(amountOut, amountInMax, path, to, deadline)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const message = `${this.fromInput} ${this.fromCur.symbol} ${this.$t('lang.swap1')} ${this.toInput} ${this.toCur.symbol}`;
          const title = this.$t('lang.swap18');
          const transactionsDetail = {
            hash,
            title,
            message,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
          this.pendingDetail.status = 1;
          this.pendingDetail.hash = hash;
        })
        .on('error', error => {
          this.pendingDetail.status = 2;
          this.pendingDetail.errormess = error.message;
          throw error;
        });
    },

    async handleGetAllPair() {
      if (this.getWrap || this.getWithdraw) {
        this.allpaths = [];
        return;
      }
      if (this.fromCur.address == '' || this.toCur.address == '') {
        return;
      }
      const allPair = getAllPair(this.fromCur, this.toCur);
      // console.log(allPair);
      const promisePairAddress = allPair.map(item => {
        const jackFactoryContract = getJackFactoryContract();
        const pairAddress = jackFactoryContract.methods.getPair(item.fromCur.address, item.toCur.address).call();
        return pairAddress;
      });
      let allPairAddress = await Promise.all(promisePairAddress);
      let lpPair = [];
      for (let i = 0; i < allPair.length; i++) {
        const lpAddress = allPairAddress[i];
        if (lpAddress == '0x0000000000000000000000000000000000000000') {
          continue;
        }
        lpPair.push({ lpAddress, ...allPair[i] });
      }

      let promiseReserve = lpPair.map(item => {
        const jackPairContract = getJackPairContract(item.lpAddress);
        const reserves = jackPairContract.methods.getReserves().call();
        return reserves;
      });

      const allReserves = await Promise.all(promiseReserve);
      let newlpPair = lpPair.map((item, index) => {
        let newItem = JSON.parse(JSON.stringify(item));
        if (newItem.fromCur.address.toLowerCase() < newItem.toCur.address.toLowerCase()) {
          newItem.fromCur.reserves = allReserves[index]._reserve0;
          newItem.toCur.reserves = allReserves[index]._reserve1;
        } else {
          newItem.fromCur.reserves = allReserves[index]._reserve1;
          newItem.toCur.reserves = allReserves[index]._reserve0;
        }
        return newItem;
      });
      let pathFrom = [];
      for (let i of newlpPair) {
        if (i.fromCur.address == this.fromCur.address) {
          pathFrom.push(i);
        }
      }
      let pathTo = [];

      for (let i of newlpPair) {
        if (i.toCur.address == this.toCur.address) {
          pathTo.push(i);
        }
      }

      let allpaths = [];
      // 算出到底有多少条路径
      for (let i = 0; i < pathFrom.length; i++) {
        const item = pathFrom[i];
        const findItem = pathTo.find(toItem => {
          return item.toCur.address == toItem.fromCur.address;
        });
        if (findItem && findItem.toCur.address == this.toCur.address) {
          allpaths.push([item, findItem]);
          continue;
        }
        if (item.toCur.address == this.toCur.address) {
          allpaths.push([item]);
        }
      }
      this.allpaths = allpaths;

      // console.log(this.allpaths);
      if (this.inputSource === 'from') {
        this.getAmountsOut();
      }

      if (this.inputSource === 'to') {
        this.getAmountsIn();
      }
    },

    // from的输入框
    async hanldeInputFrom() {
      clearTimeout(this.debounceTimer);
      // 设置新的定时器，延迟调用接口
      this.debounceTimer = setTimeout(async () => {
        this.inputSource = 'from';
        await this.getAmountsOut(); // 调用接口
        await this.updateTokenPriceAndValue(); // 更新数据
      }, 500); // 延迟时间，单位为毫秒
    },

    //to的输入框
    async hanldeInputTo() {
      clearTimeout(this.debounceTimerto);
      this.debounceTimer = setTimeout(async () => {
        this.inputSource = 'to';
        await this.getAmountsIn();
        await this.updateTokenPriceAndValue();
      }, 500); // 延迟时间，单位为毫秒
    },

    // 是否需要显示授权按钮
    async handleApproveBtnShow() {
      if (isBnb(this.fromCur) || this.fromCur.address == '') {
        this.allowanceToRouter = false;
        return;
      }
      if (this.account) {
        const erc20Contract = getErc20Contract(this.fromCur.address);
        const to = getJackRouterAddress();
        const allowance = await erc20Contract.methods.allowance(this.account, to).call();
        if (allowance == 0) {
          this.allowanceToRouter = true;
        } else {
          this.allowanceToRouter = false;
        }
      }
    },
    // 设置输入框最大值
    async handleToMax() {
      this.fromInput = this.fromCur.balance;
      this.inputSource = 'from';
      this.getAmountsOut();
    },
    async handleToMax2() {
      this.fromInput = accMul(this.fromCur.balance, 0.25);
      this.inputSource = 'from';
      this.getAmountsOut();
    },
    async handleToMax5() {
      this.fromInput = accMul(this.fromCur.balance, 0.5);
      this.inputSource = 'from';
      this.getAmountsOut();
    },
    async handleToMax7() {
      this.fromInput = accMul(this.fromCur.balance, 0.75);
      this.inputSource = 'from';
      this.getAmountsOut();
    },

    async handleToMaxto() {
      this.toInput = this.toCur.balance;
      this.inputSource = 'to';
      this.getAmountsIn();
    },
    async handleToMax2to() {
      this.toInput = accMul(this.toCur.balance, 0.25);
      this.inputSource = 'to';
      this.getAmountsIn();
    },
    async handleToMax5to() {
      this.toInput = accMul(this.toCur.balance, 0.5);
      this.inputSource = 'to';
      this.getAmountsIn();
    },
    async handleToMax7to() {
      this.toInput = accMul(this.toCur.balance, 0.75);
      this.inputSource = 'to';
      this.getAmountsIn();
    },

    async initialization() {
      this.handleCurBalance();
      // 余额定时器请求
      this.handleTimerBalance();
    },
    async handleTimerBalance() {
      window.clearTimeout(this.timerBalance);
      this.timerBalance = setInterval(async () => {
        this.handleCurBalance();
      }, 3000);
    },
    // 修改价格方向
    changePriceDirection() {
      this.priceDirection = !this.priceDirection;
      this.getPairPrice();
    },

    //
    handleSettingShow() {
      this.settingShow = true;
    },
    handleRecordShow() {
      this.transactionRecordShow = true;
    },
    async handlerSelecteCurrency(currency) {
      // this.getcurrencyLists();
      this.tokenListShow = false;
      if (this.tokenSource == 'from') {
        if (currency.address == this.toCur.address && currency.name == this.toCur.name) {
          this.turnAround();
        } else {
          this.fromCur = currency;
          sessionStorage.setItem('fromCurcurrency', JSON.stringify(currency));
        }
      } else {
        if (currency.address == this.fromCur.address && currency.name == this.fromCur.name) {
          this.turnAround();
        } else {
          this.toCur = currency;
          sessionStorage.setItem('toCurcurrency', JSON.stringify(currency));
          console.log(currency);
        }
      }
      this.handleGetAllPair();
      this.handleApproveBtnShow();
      this.handleCurBalance();
    },

    // 流动池价格
    async getPairPrice() {
      this.updateTokenPriceAndValue();
      if (parseFloat(this.fromInput) === 0 || parseFloat(this.toInput) === 0) {
        this.fromInput = '';
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        return;
      }
      const fromInput = parseFloat(this.fromInput);
      const toInput = parseFloat(this.toInput);

      if (this.priceDirection) {
        const price = accDiv(fromInput, toInput);
        this.price = significantDigits(price);
      } else {
        const price = accDiv(toInput, fromInput);
        this.price = significantDigits(price);
      }
      this.priceShow = true;

      let path = [];
      const currentPath = this.currentPaths[this.pathIndex];
      if (currentPath.length == 1) {
        path = [this.fromCur.symbol, '>', this.toCur.symbol];
      }
      if (currentPath.length == 2) {
        path = [this.fromCur.symbol, '>', currentPath[0].toCur.symbol, '>', currentPath[1].toCur.symbol];
      }
      this.pathrouter = path;
      this.getswapDetail();
    },
    async getAmountsOut() {
      this.insufficientLiquidityShow = false;
      if (parseFloat(this.fromInput) === 0 || this.fromInput == '') {
        this.toInput = '';
        this.priceShow = false;
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.toInput = '';
        this.priceShow = false;
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.toInput = this.fromInput;
        return;
      }

      if (this.multipath) {
        this.currentPaths = this.allpaths.filter(item => {
          return item.length == 1;
        });
      } else {
        this.currentPaths = this.allpaths;
      }
      let pathIndex = -1;
      let max = 0;
      console.log(this.currentPaths, 'currentPathscurrentPaths');
      for (let i = 0; i < this.currentPaths.length; i++) {
        const pathItem = this.currentPaths[i];
        let amounts = [];
        amounts[0] = parseAmount(this.fromInput, this.fromCur.decimals);
        for (let j = 0; j < pathItem.length; j++) {
          let reserveIn = pathItem[j].fromCur.reserves;
          let reserveOut = pathItem[j].toCur.reserves;
          if (reserveIn == 0 || reserveOut == 0) {
            amounts[j + 1] = 0;
            break;
          }
          let amountInWithFee = accMul(amounts[j], 997);
          let numerator = accMul(amountInWithFee, reserveOut);
          let denominator = accAdd(accMul(reserveIn, 1000), amountInWithFee);
          let amountOut = toFixed(accDiv(numerator, denominator), 0);
          amounts[j + 1] = amountOut;
        }
        if (accGt(amounts[amounts.length - 1], max)) {
          max = amounts[amounts.length - 1];
          pathIndex = i;
        }
      }
      if (pathIndex == -1 || this.currentPaths.length == 0) {
        this.insufficientLiquidityShow = true;
        this.toInput = '';
        return;
      }
      console.log(pathIndex, 'pathIndexpathIndex');
      this.pathIndex = pathIndex;
      this.toInput = formatAmount(max, this.toCur.decimals);
      this.getPairPrice();
    },

    async getAmountsIn() {
      this.insufficientLiquidityShow = false;
      if (parseFloat(this.toInput) === 0 || this.toInput == '') {
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.fromCur.address === '' || this.toCur.address === '') {
        this.fromInput = '';
        this.priceShow = false;
        return;
      }

      if (this.getWrap || this.getWithdraw) {
        this.fromInput = this.toInput;
        return;
      }

      if (this.multipath) {
        this.currentPaths = this.allpaths.filter(item => {
          return item.length == 1;
        });
      } else {
        this.currentPaths = this.allpaths;
      }
      let pathIndex = -1;
      let min = 0;

      for (let i = 0; i < 1; i++) {
        const pathItem = this.currentPaths[i];
        let amounts = [];

        amounts[pathItem.length] = parseAmount(this.toInput, this.toCur.decimals);
        for (let j = pathItem.length; j > 0; j--) {
          let reserveIn = pathItem[j - 1].fromCur.reserves;
          let reserveOut = pathItem[j - 1].toCur.reserves;
          if (reserveIn == 0 || reserveOut == 0) {
            amounts[j - 1] = 0;
            break;
          }
          let numerator = accMul(accMul(reserveIn, amounts[j]), 1000);
          let denominator = accMul(accSub(reserveOut, amounts[j]), 997);
          let amountIn = toFixed(accAdd(accDiv(numerator, denominator), 1), 0);
          amounts[j - 1] = amountIn;
        }

        if (accGt(amounts[0], 0)) {
          if (i == 0) {
            min = amounts[0];
            pathIndex = i;
          } else if (accGt(min, amounts[0])) {
            min = amounts[0];
            pathIndex = i;
          }
        }
      }

      this.pathIndex = pathIndex;
      // 流动性不足
      if (pathIndex == -1 || this.currentPaths.length == 0) {
        this.insufficientLiquidityShow = true;
        this.fromInput = '';
        return;
      }
      this.fromInput = formatAmount(min, this.fromCur.decimals);
      this.getPairPrice();
    },

    handleGetCurrency(tokenSource) {
      this.tokenSource = tokenSource;
      this.tokenListShow = true;
    },

    // 点击反转按钮
    handleTurnAround() {
      this.turnAround();
      this.handleGetAllPair();
      this.handleApproveBtnShow();
      this.handleCurBalance();
    },

    // 交换from to数据
    turnAround() {
      const tempCurrency = { ...this.toCur };
      this.toCur = { ...this.fromCur };
      this.fromCur = { ...tempCurrency };
      const tempInput = this.toInput;
      this.toInput = this.fromInput;
      this.fromInput = tempInput;
      this.inputSource = this.inputSource === 'from' ? 'to' : 'from';
    },

    async handleApprove() {
      const erc20Contract = useErc20Contract(this.fromCur.address);
      const amount = MaxUint256.toString();
      const to = getJackRouterAddress();
      const gas = await erc20Contract.methods.approve(to, amount).estimateGas({ from: this.account });
      this.approveLoading = true;
      erc20Contract.methods
        .approve(to, amount)
        .send({ from: this.account, gas: gasProcessing(gas), gasPrice: web3.utils.numberToHex(this.gasPrice) })
        .on('transactionHash', hash => {
          const transactionsDetail = {
            title: '',
            hash,
            message: `${this.$t('lang.swap14')} ${this.fromCur.symbol}`,
            addedTime: new Date().getTime(),
            from: this.account,
          };
          this.$store.commit('SETTRANSACTIONS', transactionsDetail);
        })
        .on('receipt', receipt => {
          this.handleApproveBtnShow();
          this.approveLoading = false;
        })
        .on('error', (error, receipt) => {
          this.approveLoading = false;
          this.handleApproveBtnShow();
          throw error;
        });
    },
    significantDigits,
  },
};
</script>

<style lang="less" scoped>
.xhlbox {
  display: flex;
  align-items: center;
  .xhlimg {
    cursor: pointer;
    width: 20px;
    height: 20px;
    margin-left: 30px;
  }
}
.swap {
  border: 1px solid #e7e3eb;
  background: #fff;
  width: 100%;
  border-radius: 20px;
  box-shadow: 0px 6px 32px rgba(13, 13, 13, 0.08);
  .headWrap {
    padding: 16px 16px 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .title {
      font-weight: 700;
      font-size: 18px;
    }
    .iconSet {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
    .iconRecord {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      cursor: pointer;
    }
    .iconHash {
      width: 20px;
      height: 20px;
    }
    .text {
      color: #9289c1;
      font-size: 14px;
      margin-top: 4px;
    }
  }
  .container {
    padding: 20px;
    font-weight: 600;
    line-height: 1.5;
    font-size: 12px;
    .yebox {
      display: flex;
      justify-content: start;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
    }
    .box {
      padding: 20px;
      border-radius: 24px;
      background: #edeaf1;
      margin-top: 10px;
      .btRow {
        align-items: center;
      }

      .van-cell {
        background: transparent;
        padding: 0;
      }
      .tokenSymbol {
        cursor: pointer;
        margin-right: 3px;
      }
      .selectedCurrency {
        display: flex;
        align-items: center;
        .tokenDetail {
          font-size: 20px;
          display: flex;
          align-items: center;
        }
        .tokenImg {
          margin-right: 8px;
          width: 32px;
          height: 32px;
        }
      }
    }

    .iconDown {
      position: relative;

      img {
        height: 40px;
        width: 40px;
        display: block;
        margin: 13px auto;
        cursor: pointer;
        position: relative;
        z-index: 1;
      }
      .linebox {
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        background-color: #e7e3eb;
      }
    }
  }
}
.price {
  padding: 0 10px;
  margin-top: 15px;
  font-weight: 400;
  line-height: 1.5;
  font-size: 14px;
  .text {
    font-size: 14px;
    font-weight: bold;
  }
  .opppsite {
    height: 30px;
    width: 30px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
  .changimg {
    width: 18px;
    height: 18px;
    vertical-align: middle;
    margin: 0px 4px 2px;
  }
}
.fbnum {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .maxWord {
    color: #02919d;
    font-weight: 600;
    cursor: pointer;
    padding: 0 10px;
    border-left: 1px solid #e7e3eb;
  }

  :first-child {
    border: none;
  }
}
.message {
  margin: 20px 0;
  padding: 16px;
}
.swapBtn {
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  border-radius: 16px;
}

.slipWrap {
  padding: 0 10px;
  margin-top: 15px;
  .text {
    font-size: 14px;
  }
}
.pathroutersty {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  > div {
    margin-left: 5px;
  }
}
.value-display {
  text-align: right;
}
.Feebox {
  margin-top: 4px;
  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    margin-bottom: 2px;
  }
}
</style>

<style lang="less">
.inputsty {
  .van-field__control {
    font-size: 24px !important;
    font-weight: 600 !important;
    color: #280d5e !important;
    text-align: right !important;
  }
}
</style>
