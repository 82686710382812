<template>
  <div class="pendingSwap">
    <div class="van-dialog__header">
      <!-- {{ $t('lang.swap27') }} -->
      <div class="text"></div>
      <div class="cancel" @click="handleClose"></div>
    </div>
    <div class="btContent">
      
      <div v-if="pendingDetail.status == 0">
        <img class="waitimg" src="@/assets/img/wait.gif" alt="" />
        <div class="word1">{{ $t('lang.swap19') }}</div>
        <div class="word2">
          {{ pendingDetail.fromInput }} {{ fromCur.symbol }}
          <img class="tokenImg" :src="fromCur.image" alt="" />
          <img class="rightimg" src="../../assets/img/right.png" alt="" />
          {{ pendingDetail.toInput }} {{ toCur.symbol }}
          <img class="tokenImg" :src="toCur.image" alt="" />
        </div>
        <div class="add" v-if="toCur.symbol != 'BNB'" @click="addToken(toCur)">
          <div>{{ $t('lang.swap59') }} {{ toCur.symbol }} {{ $t('lang.swap60') }}</div>
          <img src="../../assets/img/xhl.png" alt="" />
        </div>
        <!-- <div class="word3">{{ $t('lang.swap22') }}</div> -->
      </div>
      
      <div v-if="pendingDetail.status == 1"> 
        <img class="wait" src="@/assets/img/reslove.png" alt="" />
        <div class="word1">{{ $t('lang.swap23') }}</div>
        <div class="word4" @click="viewHash(pendingDetail.hash)">{{ $t('lang.swap24') }}</div>
        <van-button type="primary" class="closeBtn" @click="handleClose">{{ $t('lang.swap25') }}</van-button>
        <div class="add" v-if="toCur.symbol != 'BNB'" @click="addToken(toCur)">
          <div>{{ $t('lang.swap59') }} {{ toCur.symbol }} {{ $t('lang.swap60') }}</div>
          <img src="../../assets/img/xhl.png" alt="" />
        </div>
      </div>
     
      <div  v-if="pendingDetail.status == 2"> 
        <img class="reject" src="@/assets/img/reject.png" alt="" />
        <div class="word5" v-if="pendingDetail.errormess">{{ pendingDetail.errormess }}</div>
        <div class="word5" v-else>{{ $t('lang.swap26') }}</div>
        <van-button type="primary" class="closeBtn" @click="handleClose">{{ $t('lang.swap25') }}</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import { viewHash } from '@/utils/common';

export default {
  name: 'pendingSwap',
  props: ['pendingDetail', 'fromCur', 'toCur'],
  methods: {
    viewHash,
    handleClose() {
      this.$emit('listenClose');
    },
    async addToken(item) {
      const tokenOptions = {
        type: 'ERC20', // 代币类型
        options: item,
      };
      try {
        // 调用 MetaMask 的 `wallet_watchAsset` 方法
        const wasAdded = await window.ethereum.request({
          method: 'wallet_watchAsset',
          params: tokenOptions,
        });
      } catch (error) {
        console.error('添加代币失败：', error);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.add {
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-flex;
  cursor: pointer;
  background: #eff4f5;
  margin-top: 30px;
  border-radius: 16px;
  color: #1fc7d4;
  font-size: 16px;
  font-weight: 600;
  padding: 6.5px 20px;

  img {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
}
.pendingSwap {
  min-width: 370px;
  max-width: 480px;

  .van-dialog__header {
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    // background: linear-gradient(90deg, #f1eaf1 0%, #eaeef7 100%);
    font-weight: bold;
    font-size: 20px;
    .cancel {
      width: 20px;
      height: 20px;
      background-image: url('~@/assets/img/cancel.png');
      background-size: contain;
      background-repeat: no-repeat;
      cursor: pointer;
    }
  }
  .btContent {
    width: 100%;
    padding: 0 36px 66px;
    text-align: center;
    .wait {
      width: 250px;
    }
    .waitimg {
      width: 130px;
      margin-bottom: 20px;
    }
    .word1 {
      font-weight: bold;
      font-size: 16px;
      line-height: 28px;
      color: #280d5f;
    }
    .word2 {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #280d5f;
      // margin-top: 12px;
      .tokenImg {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        vertical-align: middle;
      }
      .rightimg {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-top: -3px;
      }
    }
    .word3 {
      font-size: 12px;
      line-height: 17px;
      color: #9d97ba;
      margin-top: 12px;
    }
    .word4 {
      cursor: pointer;
      font-weight: bold;
      font-size: 14px;
      line-height: 17px;
      margin-top: 12px;
      color: #48dae9;
    }
    .reject {
      width: 64px;
      height: 64px;
      margin-top: 50px;
    }
    .word5 {
      color: #ed4b9e;
      font-weight: 400;
      line-height: 1.5;
      font-size: 16px;
    }
    .closeBtn {
      margin: 0 auto;
      display: block;
      cursor: pointer;
      height: 40px;
      line-height: 58px;
      width: 100px;
      margin-top: 18px;
      background: #1fc7d4;
      border: none;
      box-shadow: rgba(14, 14, 44, 0.4) 0px -1px 0px 0px inset;
    }
  }
  .tokenInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .leftItem {
      display: flex;
      align-items: center;
      .input {
        margin-left: 5px;
      }
      .tokenImg {
        height: 30px;
        width: 30px;
        border-radius: 5px;
      }
    }
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .confirmBtn {
    width: 100%;
    margin-top: 22px;
    font-size: 16px;
    font-weight: bold;
  }
}
</style>
